// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { useEffect, useRef } from "react";
import { useAnimation } from "../hooks/useAnimation";
import { NavLink } from "react-router-dom";
import React, { useState } from "react";
import InputMask from "react-input-mask";
import { CITIES } from "../constants";
import cx from "classnames";
import ReCAPTCHA from "react-google-recaptcha";
import { Navigate } from "react-router-dom";
import { findDOMNode } from 'react-dom';
const Kurye = () => {

    const cityRef = useRef(null);
    const [dateType, setDateType] = useState("date");
    const [userMessenge, setUserMessenge] = useState("text");
    const [errorMessenge, setErrorMessenge] = useState("");
    const [sendData, setSendData] = useState("");
    const [formValid, setFormValid] = useState(false);
    const [otpShow, setOtpShow] = useState(false);
    const [timer, setTimer] = useState(120);
    const [timerMessage, setTimerMessage] = useState("");
    const [otpValid, setOtpValid] = useState(false);
    const [spinnerShow, isSpinnerShow] = useState(false);
    const [showTCMessage, setShowTCMessageset] = useState(false);
    const [selectedDistrict, setSelectedDistrict] = useState(-1);
    const [whereDidYouSeeUs, setWhereDidYouSeeUs] = useState("");
    const [ReCAPTCHAValue, setReCAPTCHAValue] = useState("text");
    const intervalRef = useRef(null);
    const env = window.location.href.split('/')[2];
    const  baseurl = env === 'fiyuu.com.tr' ? 'https://api.fiyuu.com.tr/enrollment' : 'https://api.dev.fiyuu.com.tr/enrollment'
    const animate = useAnimation();
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        gsm: "",
        identityNumber: false,
        birthdate: "",
        email: "",
        gender: "",
        workType: "",
        province: "",
        county: "",
        drivingLicenceType: "",
        companyType: "",
        reference: "",
        referenceName: "",
        otp: "",
        gdpr: false,
        clarification: false,
    });

    const [errors, setErrors] = useState({
        firstName: false,
        lastName: false,
        gsm: false,
        identityNumber: false,
        birthdate: false,
        email: false,
        gender: false,
        workType: false,
        province: false,
        county: false,
        drivingLicenceType: false,
        companyType: false,
        reference: false,
        referenceName: false,
        gdpr: false,
        clarification: false,
        otp: false,
    });

    // Function to handle input changes
    const handleInputChange = (event) => {

        const { name, value } = event.target;


        const newFormData = { ...formData, [name]: value };
        setFormData(newFormData);

        if (name === "firstName") {
            formData.firstName = value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
            event.target.value = value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
        }
        if (name === "lastName") {
            formData.lastName = value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
            event.target.value = value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
        }

        if (name === "province") {
            if (event.target.value !== "-1") {
                setSelectedDistrict(event.target.value);
            } else {
                setSelectedDistrict(-1);
            }
        }
        if (name === "birthdate" && value === '') {
            setDateType("text")
        }
        if (name === 'identityNumber') {
            if (TCValidation(value)) {
                if (!value.includes('_')) {
                    setErrors({ ...errors, identityNumber: true })
                } else {
                    setErrors({ ...errors, identityNumber: false })
                }
            } else {
                setErrors({ ...errors, identityNumber: false })

            }
        }

        if (name === 'birthdate') {

            const birthDate = new Date(value);
            const currentDate = new Date();
            const age = currentDate.getFullYear() - birthDate.getFullYear();
            if (age < 18) {
                setErrorMessenge("Başvuru yapabilmeniz için 18 yaşından büyük olmalısınız");
            }
            else {
                setErrorMessenge(null);
            }
        }
        if (name === 'gsm') {
            if (formData.gsm[0] === '0') {
                setErrorMessenge("Telefon numaranızı başında 0 olmadan giriniz");
            }
            else {
                setErrorMessenge(null);
            }
        }

    };

    useEffect(() => {
        animate();
        window.scrollTo(0, 0);
        if (window.innerWidth > 800) {
            setDateType("text")
        }
    }, []);

    useEffect(() => {
        if (cityRef.current) {
            cityRef.current.children[0].selected = true;
        }
    }, [selectedDistrict]);
    const validateForm = ({
        firstName,
        lastName,
        gsm,
        identityNumber,
        birthdate,
        email,
        gender,
        workType,
        province,
        county,
        drivingLicenceType,
        companyType,
        reference,
        referenceName,
        gdpr,
        clarification,
    }) => {
        const birthDate = new Date(birthdate);
        const currentDate = new Date();
        const age = currentDate.getFullYear() - birthDate.getFullYear();
        const newErrors = {

            firstName: firstName.length < 2 || firstName.length === "",
            lastName: lastName.length < 2 || lastName.length === "",
            gsm: gsm.length < 10 || gsm.includes("_") || gsm[0] === "0",
            identityNumber: TCValidation(identityNumber),
            birthdate: birthdate.length !== 10 || age < 18,
            email: !/\S+@\S+\.\S+/.test(email),
            gender: gender === "" || gender === "0",
            workType: workType === "" || workType === "0",
            province: province === "" || province === "-1",
            county: county === "" || county === "0",
            drivingLicenceType: drivingLicenceType === "" || drivingLicenceType === "0",
            companyType: companyType === "" || companyType === "0",
            reference: reference === "" || reference === "0",
            referenceName: (reference === "Çalışan Referansı" || reference === "Kadın Kurye Getir Kampanyası") && referenceName.length < 5 || referenceName.length === "",
            gdpr: gdpr === false,
            clarification: clarification === false,
        };
        setErrors(newErrors);

        return Object.values(newErrors).some((error) => error)
    };
    function onChangeReCAPTCHA(value) {
        setReCAPTCHAValue(value);
    }

  
    const otprefresh = () => {
        setTimer(120);
        timerEvent(120);
        setTimerMessage('');
        const data = formData.gsm.replace(/\s/g, "");
        applyOtpSend(data);
    }



    let interval;
    const otpClose = () => {
        setOtpShow(false);
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }
  
    }
    const timerEvent = (duration) => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }
        let remainingTime =  duration;

        intervalRef.current = setInterval(() => {
            remainingTime--;
            setTimer(remainingTime);
            if (remainingTime <= 0) {
                clearInterval(intervalRef.current);
                setTimer(0);
                setTimerMessage("OTP kodunuzun süresi doldu. Yeni bir kod almak için 'Tekrar Gönder' butonuna tıklayınız.");
            }
        }, 1000);
    };

    const applyOtpSend = (phoneNumber) => {
        setOtpShow(true);
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({
            "data": {
                "gsm": phoneNumber,
            }
        });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"

        };
        fetch(`${baseurl}/carrier/applyOtpSend`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                console.log('result', result);

            })
            .catch((error) => {
                setTimerMessage(result.message);
            });
    }

    const applyOtpVerify = () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const gsm = formData.gsm.replace(/\s/g, "");
        const raw = JSON.stringify({
            "data": {
                "gsm": gsm,
                "otpcode": formData.otp,
            }
        });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"

        };
        fetch(`${baseurl}/carrier/applyOtpVerify`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.success) {
                    sendForm();
                } else {
                    setTimerMessage(result.message);
                }


            })
            .catch((error) => {
                setTimerMessage(result.message);
            });
    }


    const sendForm = () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const data = sendData;
        const rawForm = JSON.stringify({ data });
        const requestOptionsForForm = {
            method: "POST",
            headers: myHeaders,
            body: rawForm,
            redirect: "follow"
        };
        setOtpShow(false);
        isSpinnerShow(true);
        fetch(`${baseurl}/carrier/new`, requestOptionsForForm)
            .then((response) => response.json())
            .then((result) => {
                if (result.code === 100) {
                    setFormValid(true);
                    window.scrollTo(0, 600);
                } else {
                    setErrorMessenge(result.message);
                    window.scrollTo(0, 600);
                }
                isSpinnerShow(false);
            })
            .catch((error) => {
                isSpinnerShow(false);
            });
    }


    // Function to handle form submission
    const handleSubmit = (event) => {

        event.preventDefault();

        setTimerMessage('');
        formData.otp = '';
        if (!validateForm(formData)) {

            if (ReCAPTCHAValue === "text") {
                setErrorMessenge("Lütfen ReCAPTCHA Doğrulamasını Yapınız");
                window.scrollTo(0, 600);
                return;
            }

            if (CITIES[formData.province]?.province) {
                const data = { ...formData };
                data.name = `${formData.firstName.toLocaleUpperCase('tr-TR')} ${formData.lastName.toLocaleUpperCase('tr-TR')}`
                .replace(/\s+/g, ' ')
                .trim();
                delete data.firstName;
                delete data.lastName;
                data.province = CITIES[data.province].province;
                data.referenceName = data.referenceName || "Referans Yok";
                const day = new Date(data.birthdate).getDate();
                const month = new Date(data.birthdate).getMonth() < 9 ? `0${new Date(data.birthdate).getMonth() + 1}` : new Date(data.birthdate).getMonth() + 1;
                const year = new Date(data.birthdate).getFullYear();
                data.birthdate = `${day}.${month}.${year}`;
                data.gsm = data.gsm.replace(/\s/g, "");


                setSendData(data);


                setOtpShow(true);

                applyOtpSend(data.gsm);

            }
            else {
                setErrorMessenge("Lütfen İl ve İlçe Seçiniz");

                window.scrollTo(0, 600);
            }

        } else {
            let errorCount = 0;
            for (const [key, value] of Object.entries(errors)) {
                if (value === true) {
                    errorCount++;

                }
            }
            if (errorCount === 1 && errors.birthdate === true) {
                setErrorMessenge("Başvuru yapabilmeniz için 18 yaşından büyük olmalısınız");
            }
            else {
                setErrorMessenge("Lütfen Tüm Alanları Doldurunuz");

            }
            window.scrollTo(0, 600);

        }

    };

    const TCValidation = (value) => {
        let total = 0;
        for (let i = 0; i < 10; i++) {
            total += parseInt(value[i]);
        }

        if (total % 10 !== parseInt(value[10])) {
            return true;
        }
    }

    const handleTCSubmit = (event) => {
        const tcValue = document.getElementById("tcSorgulama").value

        let total = 0;
        for (let i = 0; i < 10; i++) {
            total += parseInt(tcValue[i]);
        }
        if (tcValue.length !== 11 || total % 10 !== parseInt(tcValue[10])) {
            setTCValid(false);

            window.scrollTo(0, 600);
            if (tcValue.length === 11 && !tcValue.includes("_")) {
                setShowTCMessageset(true);
            }
        } else {
            setTCValid(true);
            setShowTCMessageset(false);
            window.scrollTo(0, 900);
        }


        event.preventDefault();
    }
    useEffect(() => {
        if (otpShow) {
            timerEvent(120); 
        }
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, [otpShow]);
    return (

        <div className="job-application-form-ctr d-flex justify-content-center flex-column align-items-center">
            <div className="w-100 d-flex flex-column justify-content-center align-items-center">
                <div className="page-banner-third w-100 d-flex justify-content-center align-items-center">
                    <div className="page-container d-flex flex-column justify-content-center align-items-center">
                        <div
                            className="image d-flex justify-content-center align-items-center"
                            data-animation="slideInUp"
                            data-animation-delay="50ms"
                        >
                            <img src="/images/jaf-image.png" alt="" />
                        </div>
                    </div>
                </div>

                <div className="jaf-inner-ctr">
                    <div className="page-container jaf-inner pb-0 d-flex flex-column justify-content-center align-items-center">
                        <div className="jaf-inner-card">
                            <h1 data-animation="zoomIn" data-animation-delay="50ms">
                                Kurye olmak istiyorum 
                            </h1>
                            <p data-animation="zoomIn" data-animation-delay="50ms">    fiyuu ailesine katılmak için ilk adımı atıyorsun. Biz "Çevik" ve "Müşteri Odaklı" adaylar arıyoruz. Eğer sen de ekibimize katılmak istersen, yapman gereken başvuru formunu ayrıntılı ve özenli bir şekilde doldurmak. Sonrasında İşe Alım ekiplerimiz seninle bağlantıya geçecek.
                            </p>
                        </div>

                        <div className="jaf-inner-form w-100">
                            {formValid ? (
                                <Navigate to="/form-result" replace={true} />
                            ) : (

                                <>
                                    <form
                                        onSubmit={handleSubmit}
                                        style={{ position: "relative" }}
                                        className="w-100 d-flex justify-content-center flex-column align-items-center"
                                    >
                                        {errorMessenge ? (<div className="warning"> {errorMessenge} </div>) : null}
                                        <div className="form-content w-100 row m-0 justify-content-between">
                                            <div className="col-12 col-lg-3 p-0 form-description mb-5 mb-lg-0">
                                                <h3>Kişisel Bilgiler</h3>
                                            </div>




                                            {/* <div className="col-12 col-lg-7 p-0"> */}

                                            <div className="col-12 col-lg-7 p-0 form-items d-flex flex-column">
                                                <div className="form-items-secondary">
                                                    <div className="form-control-ctr">
                                                        <input
                                                            type="text"
                                                            placeholder="Ad *"
                                                            id="firstName"
                                                            name="firstName"
                                                            value={formData.firstName}
                                                            onChange={handleInputChange}
                                                            className={
                                                                errors.firstName ? "error-form-item" : "form-control"
                                                            }
                                                        />
                                                    </div>
                                                    <div className="form-control-ctr">
                                                        <input
                                                            type="text"
                                                            placeholder="Soyad *"
                                                            id="lastName"
                                                            name="lastName"
                                                            value={formData.lastName}
                                                            onChange={handleInputChange}
                                                            className={
                                                                errors.lastName ? "error-form-item" : "form-control"
                                                            }
                                                        />
                                                    </div>
                                                    <div className="form-control-ctr">
                                                        <InputMask
                                                            type="gsm"
                                                            mask="999 999 99 99"
                                                            placeholder="Cep Telefonu *"
                                                            id="gsm"
                                                            name="gsm"
                                                            value={formData.gsm}
                                                            onChange={handleInputChange}
                                                            className={
                                                                errors.gsm ? "error-form-item" : "form-control"
                                                            }
                                                        />

                                                    </div>
                                                </div>
                                                <div className="form-items w-100">


                                                    <div className="form-control-ctr">
                                                        <InputMask
                                                            type="tckn"
                                                            mask="99999999999"
                                                            placeholder="TC Kimlik No *"
                                                            id="identityNumber"
                                                            name="identityNumber"
                                                            value={formData.identityNumber}
                                                            onChange={handleInputChange}
                                                            className={
                                                                errors.identityNumber ? "error-form-item" : "form-control"
                                                            }
                                                        />
                                                        {errors.identityNumber && <small className="error-text">Geçersiz Kimlik Numarası</small>}
                                                    </div>
                                                    <div className="form-control-ctr birthdate-label" style={{ position: 'relative' }}>
                                                        <label htmlFor="birthdate">Doğum Tarihinizi Giriniz *</label>
                                                        <input
                                                            style={{ width: "100%", borderColor: errors.birthdate ? "#e61b80" : "#e0e0e0" }}
                                                            type={dateType}
                                                            onClick={() => setDateType("date")}

                                                            placeholder="Doğum Tarihinizi Giriniz *"
                                                            className="form-control"
                                                            id="birthdate"
                                                            name="birthdate"
                                                            value={formData.birthdate}
                                                            onChange={handleInputChange}

                                                        />
                                                    </div>

                                                    <div className="form-control-ctr">
                                                        <input
                                                            type="email"
                                                            placeholder="E-posta *"
                                                            id="email"
                                                            name="email"
                                                            value={formData.email}
                                                            onChange={handleInputChange}
                                                            className={
                                                                errors.email ? "error-form-item" : "form-control"
                                                            }
                                                        />
                                                    </div>
                                                    <div className="form-control-ctr">
                                                        <select
                                                            id="gender"
                                                            name="gender"
                                                            value={formData.gender}
                                                            onChange={handleInputChange}
                                                            className={
                                                                errors.gender
                                                                    ? "error-form-item-select"
                                                                    : "form-select"
                                                            }
                                                        >
                                                            <option value="0" defaultValue="0">
                                                                Cinsiyet *
                                                            </option>
                                                            <option value="Kadın">Kadın</option>
                                                            <option value="Erkek">Erkek</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-content w-100 row m-0 justify-content-between">
                                            <div className="col-12 col-lg-3 p-0 form-description mb-5 mb-lg-0">
                                                <h3>Çalışma Şekli</h3>
                                                <p>Çalışmak istediğiniz yeri ve zamanları seçiniz</p>
                                            </div>

                                            <div className="col-12 col-lg-7 p-0 form-items d-flex flex-column">
                                                <div className="form-items-secondary">
                                                    <div className="form-control-ctr">
                                                        <select
                                                            id="workType"
                                                            name="workType"
                                                            value={formData.workType}
                                                            onChange={handleInputChange}
                                                            className={
                                                                errors.workType
                                                                    ? "error-form-item-select"
                                                                    : "form-select"
                                                            }
                                                        >
                                                            <option value="0" defaultValue="0">
                                                                Çalışma Zamanı *
                                                            </option>
                                                            <option value="Tam Zamanlı">Tam Zamanlı</option>
                                                            <option value="Yarı Zamanlı">Yarı Zamanlı</option>
                                                        </select>
                                                    </div>
                                                    <div className="form-control-ctr">
                                                        <select
                                                            id="province"
                                                            name="province"
                                                            value={formData.province}
                                                            className={
                                                                errors.province ? "error-form-item-select" : "form-select"
                                                            }
                                                            onChange={handleInputChange}
                                                        >
                                                            <option value={-1}> İl Seçiniz *</option>
                                                            {CITIES.map((city, index) => (
                                                                <option key={index} value={index}>
                                                                    {city.province}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className="form-control-ctr">
                                                        <select
                                                            id="county"
                                                            name="county"
                                                            value={formData.county}
                                                            onChange={handleInputChange}
                                                            className={
                                                                errors.county
                                                                    ? "error-form-item-select"
                                                                    : "form-select"
                                                            }
                                                            disabled={selectedDistrict === -1}
                                                            ref={cityRef}
                                                        >
                                                            <option value="0" defaultValue="0">
                                                                {" "}
                                                                İlçe Seçiniz *
                                                            </option>
                                                            {selectedDistrict !== -1 &&
                                                                CITIES[selectedDistrict].ilceleri.map(
                                                                    (county, index) => (
                                                                        <option key={index} value={county}>
                                                                            {county}
                                                                        </option>
                                                                    )
                                                                )}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="form-items">
                                                    <div className="form-control-ctr">
                                                        <select
                                                            id="drivingLicenceType"
                                                            name="drivingLicenceType"
                                                            value={formData.drivingLicenceType}
                                                            onChange={handleInputChange}
                                                            className={
                                                                errors.drivingLicenceType
                                                                    ? "error-form-item-select"
                                                                    : "form-select"
                                                            }
                                                        >
                                                            <option value="0" defaultValue="0">
                                                                Ehliyet tipi seçiniz *
                                                            </option>
                                                            <option value="A">A</option>
                                                            <option value="A1">A1</option>
                                                            <option value="A2">A2</option>
                                                            <option value="B">B</option>
                                                            <option value="Ehliyetim Yok">Ehliyetim Yok</option>
                                                        </select>
                                                    </div>
                                                    <div className="form-control-ctr">
                                                        <select
                                                            id="companyType"
                                                            name="companyType"
                                                            value={formData.companyType}
                                                            onChange={handleInputChange}
                                                            className={
                                                                errors.companyType
                                                                    ? "error-form-item-select"
                                                                    : "form-select"
                                                            }
                                                        >
                                                            <option value="0" defaultValue="0">
                                                                Şirket tipi seçiniz *
                                                            </option>
                                                            <option value="Şahıs Şirketim Var">Şahıs Şirketim Var</option>
                                                            <option value="Şahıs Şirketim Yok">Şahıs şirketim Yok</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-content w-100 row m-0 justify-content-between border-0">
                                            <div className="col-12 col-lg-3 p-0 form-description mb-5 mb-lg-0">
                                                <h3>Bizi Nereden Gördünüz</h3>
                                            </div>

                                            <div className="col-12 col-lg-7 p-0 form-items d-flex flex-column">
                                                <div className="form-items w-100  d-flex">
                                                    <div className="form-control-ctr">
                                                        <select
                                                            id="reference"
                                                            name="reference"
                                                            value={formData.reference}
                                                            className={
                                                                errors.reference
                                                                    ? "error-form-item-select"
                                                                    : "form-select"
                                                            }
                                                            onChange={handleInputChange}
                                                        >
                                                            <option value="0" defaultValue="0">
                                                            Bizi Nereden Gördünüz *
                                                            </option>
                                                            <option value="24 Saatte İş">24 Saatte İş</option>
                                                            <option value="Bitoniş">Bitoniş</option>
                                                            <option value="Çalışan Referansı">Çalışan Referansı</option>
                                                            <option value="Eleman.net">Eleman.net</option>
                                                            <option value="Facebook">Facebook</option>
                                                            <option value="Instagram">Instagram</option>
                                                            <option value="İşin Olsun">İşin Olsun</option>
                                                            <option value="Kadın Kurye Getir Kampanyası">Kadın Kurye Getir Kampanyası</option>
                                                            <option value="Kariyer.net">Kariyer.net</option>
                                                            <option value="Sahibinden">Sahibinden</option>
                                                            <option value="Secret CV">Secret CV</option>
                                                            <option value="Tiktok">Tiktok</option>
                                                            <option value="Yeni Bir İş">Yeni Bir İş</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                {(formData.reference === "Çalışan Referansı" ||
                                                    formData.reference === "Kadın Kurye Getir Kampanyası") && (
                                                        <div className="form-control-ctr">
                                                            <input
                                                                type="text"
                                                                placeholder="Ad Soyad *"
                                                                id="referenceName"
                                                                name="referenceName"
                                                                value={formData.referenceName}
                                                                onChange={handleInputChange}
                                                                className={
                                                                    errors.referenceName
                                                                        ? "error-form-item"
                                                                        : "form-control"
                                                                }
                                                            />
                                                        </div>
                                                    )}
                                            </div>
                                        </div>

                                        <div
                                            className="kvkk-onay"

                                        >
                                            <button
                                                type="button"
                                                className={cx("disabledButton", {
                                                    "error-form-item-button": errors.gdpr,
                                                })}
                                                onClick={() => {
                                                    setFormData({ ...formData, gdpr: !formData.gdpr });
                                                    setErrors({ ...errors, gdpr: false });
                                                }}
                                            >
                                                {formData.gdpr ? (
                                                    <img src="/images/button-selected.svg" alt="" />
                                                ) : (
                                                    <img src="/images/button-disabled.svg" alt="" />
                                                )}
                                            </button>
                                            <span>
                                                <input
                                                    type="checkbox"
                                                    className="kvkkCheckbox"
                                                    style={{ display: "none" }}
                                                    name="gdpr"
                                                    onChange={handleInputChange}
                                                />
                                                <label className="ms-4">
                                                    <NavLink to="/files/Fiyuu-Kurye-Adayi-Aydinlatma-Metni.pdf" target="_blank">
                                                        Aydınlatma Metnimize <b>buradan</b> ulaşabilirsiniz.
                                                    </NavLink>
                                                  
                                                </label>
                                            </span>
                                        </div>

                                        <div
                                            className="kvkk-onay"
                                        >
                                            <button

                                                className={cx("disabledButton", {
                                                    "error-form-item-button": errors.clarification,
                                                })}
                                                type="button"
                                                onClick={() => {
                                                    setFormData({ ...formData, clarification: !formData.clarification });
                                                    setErrors({ ...errors, clarification: false });
                                                }}
                                            >
                                                {formData.clarification ? (
                                                    <img src="/images/button-selected.svg" alt="" />
                                                ) : (
                                                    <img src="/images/button-disabled.svg" alt="" />
                                                )}
                                            </button>
                                            <span>
                                                <input
                                                    type="checkbox"
                                                    className="kvkkCheckbox"
                                                    style={{ display: "none" }}
                                                    name="clarification"
                                                    onChange={handleInputChange}
                                                />
                                                <label className="ms-4">
                                                    <NavLink to="/files/Fiyuu-Kurye-Adayi-Acik-Riza-Metni.pdf" target="_blank">
                                                    Kişisel verilerimin <b>Açık Rıza Metni</b>'nde belirtilen hususlar kapsamında işlenmesine onay veriyorum.
                                                    </NavLink>
                                                </label>
                                            </span>
                                        </div>

                                      
                                        <br />
                                        <ReCAPTCHA
                                            sitekey="6LdCTN0pAAAAAMieGGlcfsEbecDczxfdShWnxD3Z"
                                            onChange={onChangeReCAPTCHA}
                                        />
                                        <br />

                                        <button
                                            className="submit-btn d-flex mt-5 justify-content-center align-items-center"
                                            type="submit"
                                            disabled={spinnerShow}
                                        >
                                            <span className="text-center d-inline-block">
                                                {spinnerShow ? <img src="/images/spinner.gif" style={{ width: "20px" }} alt="" /> : null} Hemen Başvur
                                            </span>
                                        </button>
                                    </form>



                                </>
                            )}

                        </div>


                    </div>
                </div>
            </div>
            {otpShow ? (


                <div className="modal-mask">

                    <div className="modal-box">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-x-circle-fill" viewBox="0 0 16 16" onClick={otpClose}>
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
                        </svg>
                        <span>Lütfen telefonunuza gelen doğrulama kodunu giriniz.</span>

                        <div className="form-items">
                            <div className="form-control-ctr">
                                <InputMask
                                    type="text"
                                    mask="999999"
                                    placeholder="Kodu giriniz"
                                    id="otp"
                                    name="otp"
                                    value={formData.otp}
                                    onChange={handleInputChange}
                                    className={
                                        errors.otp ? "error-form-item" : "form-control"
                                    }
                                />
                                <div className="timer">{timer}</div>
                            </div>

                        </div>

                        {timerMessage ? (
                            <small>{timerMessage}</small>
                        ) : null

                        }

                        <div className="botton-box">
                            <button
                              disabled={formData.otp.length < 6 || formData.otp.includes("_") || timer === 0}
                                onClick={applyOtpVerify}
                                className="submit-btn d-flex accept"
                            >
                                <span className="text-center d-inline-block">
                                    <svg style={{ top: "10px" }} xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-check" viewBox="0 0 16 16">
                                        <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z" />
                                    </svg>
                                    Onayla
                                </span>
                            </button>
                            <button
                                disabled={!timerMessage}
                                className=" refresh"
                                onClick={otprefresh}
                            >
                                <span className="text-center d-inline-block">

                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-repeat-1" viewBox="0 0 16 16">
                                        <path d="M11 4v1.466a.25.25 0 0 0 .41.192l2.36-1.966a.25.25 0 0 0 0-.384l-2.36-1.966a.25.25 0 0 0-.41.192V3H5a5 5 0 0 0-4.48 7.223.5.5 0 0 0 .896-.446A4 4 0 0 1 5 4zm4.48 1.777a.5.5 0 0 0-.896.446A4 4 0 0 1 11 12H5.001v-1.466a.25.25 0 0 0-.41-.192l-2.36 1.966a.25.25 0 0 0 0 .384l2.36 1.966a.25.25 0 0 0 .41-.192V13h6a5 5 0 0 0 4.48-7.223Z" />
                                        <path d="M9 5.5a.5.5 0 0 0-.854-.354l-1.75 1.75a.5.5 0 1 0 .708.708L8 6.707V10.5a.5.5 0 0 0 1 0z" />
                                    </svg>
                                    Tekrar gönder
                                </span>
                            </button>
                        </div>

                    </div>
                </div>


            ) : (
                <></>
            )}
        </div>
    );
};

export default Kurye;
