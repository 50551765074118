import { createRoot } from 'react-dom/client';
import App from './App.tsx';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from "@sentry/react";

// Sentry Konfigürasyonu
Sentry.init({
  dsn: "https://359aa44676240608dc6370981bb10d4b@o4508488581316608.ingest.de.sentry.io/4508488583872592",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

// React Uygulama Başlatma
const container = document.getElementById('root'); // ID 'root' olmalı
if (container) {
  const root = createRoot(container);
  root.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
} else {
  console.error("Root container not found!");
}