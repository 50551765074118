import { useEffect } from "react";
import { useAnimation } from "../hooks/useAnimation";

const FormSuccsessPage = () => {
    const animate = useAnimation()

    useEffect(() => {
        animate()
        window.scrollTo(0, 400);
    }, [])

    return (
        <div className="job-application-form-ctr d-flex justify-content-center flex-column align-items-center">
            <div className="w-100 d-flex flex-column justify-content-center align-items-center">
                <div className="page-banner-third w-100 d-flex justify-content-center align-items-center">
                    <div className="page-container d-flex flex-column justify-content-center align-items-center">
                        <div
                            className="image d-flex justify-content-center align-items-center"
                            data-animation="slideInUp"
                            data-animation-delay="50ms"
                        >
                            <img src="/images/jaf-image.png" alt="" />
                        </div>
                    </div>
                </div>

                <div className="jaf-inner-ctr">
                    <div className="page-container jaf-inner pb-0 d-flex flex-column justify-content-center align-items-center">
                        <div className="jaf-inner-card">
                            <h1 data-animation="zoomIn" data-animation-delay="50ms">
                                Kurye olmak istiyorum
                            </h1>
                            <p data-animation="zoomIn" data-animation-delay="50ms">    fiyuu ailesine katılmak için ilk adımı atıyorsun. Biz "Çevik" ve "Müşteri Odaklı" adaylar arıyoruz. Eğer sen de ekibimize katılmak istersen, yapman gereken başvuru formunu ayrıntılı ve özenli bir şekilde doldurmak. Sonrasında İşe Alım ekiplerimiz seninle bağlantıya geçecek.
                            </p>
                        </div>
                        <div className="jaf-inner-form w-100">
                            <div className="result w100" style={{ color: "#e61b80" }}>Başvurunuz başarılı bir şekilde kaydedilmiştir. İlginiz ve katılımınız için teşekkür ederiz.</div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default FormSuccsessPage;