import { useEffect, } from "react";
import { useState } from "react";
import { useAnimation } from "../hooks/useAnimation";
import { render } from 'storyblok-rich-text-react-renderer';
const RidersGizlilikSozlesmesi = () => {
    const animate = useAnimation()
    const [data, setData] = useState(null);
    const loadNews = async () => {
     
        try {
            const url = window.location.href.split('/')[2];
            const response = await fetch(`https://api.storyblok.com/v2/cdn/stories/?version=${url === 'fiyuu.com.tr' ? 'published' : 'draft'}&token=PU48xeT0YARVgQPLiYGmwAtt`)

             const pureData = await response.json();
            
        
             pureData.stories.forEach((element: any) => {
              
                if (element.slug.indexOf('riders-gizlilik-sozlesmesi') > -1) {
                    setData(element)
                }
            
            });
          
        } catch (error) {

        }
    }
    useEffect(() => {
        animate()
        window.scrollTo(0, 0);
        loadNews()

    }, [])

    return (
        data &&
        <div className="d-flex justify-content-center page-ctr flex-column align-items-center" >
            <div className="w-100 page-container flex-column justify-content-center align-items-center">
                <h1 className="page-title mb-5">
                    {(data as any).content.title} 
                </h1>
                <div className="page-content-card mt-5 storyblok-content">
                    {render((data as any).content.content)}
                </div>
            </div>
        </div>
    )
}

export default RidersGizlilikSozlesmesi;