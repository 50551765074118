import { useEffect } from "react";
import { useAnimation } from "../hooks/useAnimation";

const Aydinlatma = () => {
    const animate = useAnimation()

    useEffect(() => {
        animate()
        window.scrollTo(0, 0);
    }, [])

    return (
        <div className="about-us-ctr d-flex justify-content-center flex-column align-items-center">
            <div className="w-100 d-flex flex-column justify-content-center align-items-center">
                <div className="why-us page-container w-100 d-flex flex-column justify-content-start align-items-start">
                    <h1 className="title">
                        ATA	EXPRESS	ELEKTRONİK	İLETİŞİM	TANITIM	PAZARLAMA	DAĞITIM	SANAYİ	VE	TİCARET	A.Ş.
                        MÜŞTERİ	KİŞİSEL	VERİLERİN	KORUNMASI	VE	İŞLENMESİ	AYDINLATMA	METNİ
                    </h1>
                    <div className="content-form">
                        <p>

                       <strong>  a) Veri	Sorumlusu	ve	Temsilcisi</strong>
                        6698	 sayılı	 Kişisel	 Verilerin	 Korunması	 Kanunu	 <b>(“Kanun”)</b>	 uyarınca,	 kişisel	 verileriniz;	 veri	 sorumlusu
                        olarak	Ata	Express	Elektronik	İletişim	Tanıtım	Pazarlama	Dağıtım	Sanayi	ve	Ticaret	Anonim	Şirketi (“Ata
                        Express”	veya	“Şirket”)	tarafından	aşağıda	açıklanan	kapsamda	işlenebilecektir.
                        <br />
                        <br />
                        Kişisel verilerinizin Şirketimiz tarafından işlenme amaçları konusunda detaylı bilgilere 
                        <a href="www.fiyuu.com.tr">www.fiyuu.com.tr</a>  adresinden kamuoyu ile paylaşılmış olan Ata	 Express	 Elektronik	 İletişim	 Tanıtım
                        Pazarlama	 Dağıtım	 Sanayi	 ve	 Ticaret	 Anonim	 Şirketi Kişisel Verilerin Korunması ve İşlenmesi
                        Politikasından ulaşabilirsiniz.
                        <br />
                        <br />
                        <strong>b) Kişisel Verilerin Hangi Amaçla İşleneceği</strong>
                        Toplanan kişisel verileriniz; Şirketimizin ürün ve hizmetlerinden yararlanmanız kapsamında  <br /><br />
                        • Şirketimiz tarafından yürütülen ticari faaliyetlerin gerçekleştirilmesi için ilgili iş birimlerimiz
                        tarafından gerekli çalışmaların yapılması ve buna bağlı iş süreçlerinin yürütülmesi  <br />
                        • Ürün ve hizmetlerden sizlerin faydalandırması için gerekli çalışmaların iş birimlerimiz tarafından
                        yapılması ve ilgili iş süreçlerinin yürütülmesi  <br />
                        • Ürün ve hizmetlerin sizlerin beğeni, kullanım alışkanlıklarınız ve ihtiyaçlarınıza göre
                        özelleştirilerek sizlere önerilmesi ve tanıtılması için gerekli olan aktivitelerin planlanması ve
                        icrası  <br />
                        amaçlarıyla (“Amaçlar”) Kanun’un 5. ve 6. maddelerinde belirtilen kişisel veri işleme şartları ve amaçları
                        dahilinde işlenecektir.  <br /><br />
                       <strong> c) İşlenen Kişisel	Verilerin	Kimlere	ve	Hangi	Amaçla	Aktarılabileceği</strong>
                        Toplanan	 kişisel	 verileriniz;	 yukarıda	 belirtilen	 amaçların	 gerçekleştirilmesi	 doğrultusunda;
                        tedarikçilerimize,	kanunen	yetkili	kamu	kurumlarına	ve kanunen	yetkili özel	kişilere	Kanun’un	8.	ve	9.
                        maddelerinde	belirtilen	kişisel	veri	işleme	şartları	ve	amaçları	çerçevesinde	aktarılabilecektir. <br />
                        <br />
                       <strong> ç) Kişisel Veri Toplamanın Yöntemi ve Hukuki Sebebi</strong>
                        Kişisel	verileriniz,	Şirketimizin ürün ve hizmetlerinden yararlanmanız kapsamında Şirketimiz	tarafından
                        [___]	elektronik	ortamda,	fiyuu	internet	sitesi	ve	mobil	uygulaması üzerinden	bizimle	temas	kurduğunuz
                        kanal yoluyla sözleşmenin	 ifası	 hukuki	 sebebine	 dayalı	 olarak	 ürün	 ve	 hizmetlerden	 sizlerin
                        faydalanmasını	 sağlanması	 ve	 açık	 rıza	 vermeniz	 halinde	 ürün	 ve	 hizmetlerimizin	 beğeni	 ve	 kullanım
                        alışkanlıklarınıza	göre	özelleştirilerek	size	sunulması	amacıyla	toplanmaktadır. <br /> <br />
                        <strong>d) Kişisel Veri Sahibinin Kanun’un 11. maddesinde Sayılan Hakları</strong>
                        Kişisel	veri	sahibi	olarak	Kanun’un	11.	maddesi	uyarınca	aşağıdaki	haklara	sahip	olduğunuzu	bildiririz:
                        • Kişisel	verilerinizin	işlenip	işlenmediğini	öğrenme, <br />
                        • Kişisel	verileriniz	işlenmişse	buna	ilişkin	bilgi	talep	etme, <br />
                        • Kişisel	 verilerinizin	 işlenme	 amacını	 ve	 bunların	 amacına	 uygun	 kullanılıp	 kullanılmadığını
                        öğrenme, <br />
                        • Yurt	içinde	veya	yurt	dışında	kişisel	verilerinizin	aktarıldığı	üçüncü	kişileri	bilme, <br />
                        • Kişisel	verilerinizin	eksik	veya	yanlış	işlenmiş	olması	halinde	bunların	düzeltilmesini	isteme	ve	bu
                        kapsamda	yapılan	işlemin	kişisel	verilerinizin	aktarıldığı	üçüncü	kişilere	bildirilmesini	isteme, <br />
                        • Kanun	 ve	 ilgili	 diğer	 kanun	 hükümlerine	 uygun	 olarak	 işlenmiş	 olmasına	 rağmen,	 işlenmesini
                        gerektiren	 sebeplerin	 ortadan	 kalkması	 halinde	 kişisel	 verilerinizin	 silinmesini	 veya	 yok
                        edilmesini	isteme	ve	bu	kapsamda	ve	kişisel	verilerinizin	eksik	veya	yanlış	işlenmiş	olması	halinde
                        yapılan	işlemlerin	kişisel	verilerinizin	aktarıldığı	üçüncü	kişilere	bildirilmesini	isteme, <br />
                        • İşlenen	verilerinizin	münhasıran	otomatik	sistemler	vasıtasıyla	analiz	edilmesi	suretiyle	kişinin
                        kendisi	aleyhine	bir	sonucun	ortaya	çıkmasına	itiraz	etme,
                        • Kişisel	 verilerinizin	 kanuna	 aykırı	 olarak	 işlenmesi	 sebebiyle	 zarara	 uğraması	 halinde	 zararın
                        giderilmesini	talep	etme. <br /> <br />
                        Yukarıda	sıralanan	haklarınıza	yönelik	başvurularınızı,	www.fiyuu.com.tr adresinden	ulaşabileceğiniz
                        Veri	 Sahibi	 Başvuru	 Formu’nu doldurarak	Şirketimize iletebilirsiniz.	Talebinizin	 niteliğine	göre	en	 kısa
                        sürede	ve	en	geç	otuz	gün	içinde	başvurularınız	ücretsiz	olarak	sonuçlandırılacaktır;	ancak	işlemin	ayrıca
                        bir	 maliyet	 gerektirmesi	 halinde	 Kişisel	 Verileri	 Koruma	 Kurulu	 tarafından	 belirlenecek	 tarifeye	 göre
                        tarafınızdan	ücret	talep	edilebilecektir.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Aydinlatma;