import { useEffect } from "react";
import { useAnimation } from "../hooks/useAnimation";

const Contact = () => {
    const animate = useAnimation()

    useEffect(() => {
        animate()
        window.scrollTo(0, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="contact-ctr d-flex justify-content-center flex-column align-items-center">
            <div className="w-100 d-flex flex-column justify-content-center align-items-center">
                <div className="page-banner w-100 d-flex justify-content-center align-items-center"  >
                    <div
                        className="page-container p-0 d-flex flex-column flex-lg-row justify-content-between align-items-center align-items-lg-center">
                        <div className="page-banner__card" data-animation="slideInRight">
                            <h1 className="page-banner__card-title mb-4">
                                Bize Ulaşın
                            </h1>

                            <p className="page-banner__card-text mt-3">
                                Türkiye'nin 9 ilinde 11 operasyon merkeziyle sizelere en hızlı ve güvenilir hizmeti vermeye genişleyen ağımızla devam ediyoruz.
                            </p>
                        </div>

                        <div className="page-banner__image mt-5 mt-lg-0" data-animation="slideInLeft">
                            <img src="/images/contact-banner.png" alt="" />
                        </div>
                    </div>
                </div>

                <div className="contact-inner page-container w-100">
                    <div className="contact-cards w-100">
                
                        <div data-animation="zoomIn" data-animation-delay="50ms"
                            className="contact-cards__item d-flex flex-column justify-content-between align-items-start">
                            <div className="d-flex flex-column justify-content-start align-items-start">
                                <h5 className="contact-item-title">
                                    İstanbul Seyrantepe Operasyon Merkezi

                                </h5>
                                <p className="contact-item-address">
                                    Seyrantepe Mah. İbrahim Karaoğlanoğlu Cad. No:105F İstanbul/Kağıthane
                                </p>
                                  {/* <a className="contact-item-phone mb-2" href="tel:05317637834">
                                    0850 220 70 90
                                </a> */}
                                <a className="contact-item-email mb-3" href="mailto:kuryeik@fiyuu.com.tr">
                                    kuryeik@fiyuu.com.tr
                                </a>

                            </div>
                            <div className="iframe-area">
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3006.616579313059!2d28.993182877429838!3d41.0992233713381!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab681ba7d9497%3A0xd08e4997d3d69eb1!2zU2V5cmFudGVwZSwgxLBicmFoaW0gS2FyYW_En2xhbm_En2x1IENkLiBObzoxMDUsIDM0NDE4IEvDosSfxLF0aGFuZS_EsHN0YW5idWw!5e0!3m2!1sen!2str!4v1712143714364!5m2!1sen!2str"
                                    style={{ border: "0" }} allowFullScreen loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>


                        <div data-animation="zoomIn" data-animation-delay="50ms"
                            className="contact-cards__item d-flex flex-column justify-content-between align-items-start">
                            <div className="d-flex flex-column justify-content-start align-items-start">
                                <h5 className="contact-item-title">
                                    İstanbul Beylikdüzü Operasyon Merkezi                            </h5>
                                <p className="contact-item-address">
                                    Barbaros Hayrettin Paşa Mah. Necip Fazıl Kısakürek Cad. Nefes Evleri A ve B Blok No 19/5 Esenyurt/İstanbul
                                </p>
                                       {/* <a className="contact-item-phone mb-2" href="tel:05317637834">
                                    0850 220 70 90
                                </a> */}
                                <a className="contact-item-email mb-3" href="mailto:kuryeik@fiyuu.com.tr">
                                    kuryeik@fiyuu.com.tr
                                </a>

                            </div>
                            <div className="iframe-area">
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1505.037115200726!2d28.64285820809718!3d41.02363190920272!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14b55f13dec822cb%3A0x1671ff0ded68e0c4!2sNefes%20Site%20Y%C3%B6netimi!5e0!3m2!1str!2str!4v1724236689744!5m2!1str!2str"
                                    style={{ border: "0" }} allowFullScreen loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>



                        <div data-animation="zoomIn" data-animation-delay="50ms"
                            className="contact-cards__item d-flex flex-column justify-content-between align-items-start">
                            <div className="d-flex flex-column justify-content-start align-items-start">
                                <h5 className="contact-item-title">
                                    İstanbul Anadolu Operasyon Merkezi

                                </h5>
                                <p className="contact-item-address">
                                    Bostancı Mahallesi Emin Ali Paşa Caddesi No: 126/A Kadıköy/İstanbul
                                </p>
                                       {/* <a className="contact-item-phone mb-2" href="tel:05317637834">
                                    0850 220 70 90
                                </a> */}
                                <a className="contact-item-email mb-3" href="mailto:kuryeik@fiyuu.com.tr">
                                    kuryeik@fiyuu.com.tr
                                </a>

                            </div>
                            <div className="iframe-area">



                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1506.5891432233132!2d29.098792762712!3d40.9556729970595!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cac6fbc3275a63%3A0xec969561b1014b2!2zQm9zdGFuY8SxLCBFbWluIEFsaSBQYcWfYSBDZC4gTm86MTI2LCAzNDc0NCBLYWTEsWvDtnkvxLBzdGFuYnVs!5e0!3m2!1str!2str!4v1723793839978!5m2!1str!2str"
                                    style={{ border: "0" }} allowFullScreen loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>

                        <div data-animation="zoomIn" data-animation-delay="50ms"
                            className="contact-cards__item d-flex flex-column justify-content-between align-items-start">
                            <div className="d-flex flex-column justify-content-start align-items-start">
                                <h5 className="contact-item-title">
                                    Ankara Operasyon Merkezi
                                </h5>
                                <p className="contact-item-address">
                                    Metin Oktay Mah. Hınıs Cad. No:20/A Çankaya / Ankara
                                </p>
                                       {/* <a className="contact-item-phone mb-2" href="tel:05317637834">
                                    0850 220 70 90
                                </a> */}
                                <a className="contact-item-email mb-3" href="mailto:kuryeik@fiyuu.com.tr">
                                    kuryeik@fiyuu.com.tr
                                </a>

                            </div>
                            <div className="iframe-area">
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3060.4191984271224!2d32.87268217739537!3d39.90963417152578!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d34fb610d8c5f5%3A0xb5534dc48bcffb53!2zTWV0aW4gT2t0YXksIEjEsW7EsXMgQ2QuIE5vOjIwIEQ6YSwgMDY2NzAgw4dhbmtheWEvQW5rYXJh!5e0!3m2!1str!2str!4v1712146032242!5m2!1str!2str"
                                    style={{ border: "0" }} allowFullScreen loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                        <div data-animation="zoomIn" data-animation-delay="50ms"
                            className="contact-cards__item d-flex flex-column justify-content-between align-items-start">
                            <div className="d-flex flex-column justify-content-start align-items-start">
                                <h5 className="contact-item-title">
                                    İzmir Operasyon Merkezi
                                </h5>
                                <p className="contact-item-address">
                                    Yunus Emre, Şehit. Pilot Volkan Koçyiğit Bulvarı No: 5 Karabağlar/İzmir
                                </p>
                                       {/* <a className="contact-item-phone mb-2" href="tel:05317637834">
                                    0850 220 70 90
                                </a> */}
                                <a className="contact-item-email mb-3" href="mailto:kuryeik@fiyuu.com.tr">
                                    kuryeik@fiyuu.com.tr
                                </a>

                            </div>
                            <div className="iframe-area">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3127.800836020194!2d27.131672912895752!3d38.376728571722346!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14bbdf23966d2aeb%3A0xe80e7bab230e4132!2zWXVudXMgRW1yZSwgxZ5odC4gUGlsb3QgVm9sa2FuIEtvw6d5acSfaXQgQmx2IE5vOjUsIDM1MTMwIEthcmFiYcSfbGFyL8Swem1pcg!5e0!3m2!1str!2str!4v1729159021349!5m2!1str!2str"
                                    style={{ border: "0" }} allowFullScreen loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                        <div data-animation="zoomIn" data-animation-delay="50ms"
                            className="contact-cards__item d-flex flex-column justify-content-between align-items-start">
                            <div className="d-flex flex-column justify-content-start align-items-start">
                                <h5 className="contact-item-title">
                                    Bursa Operasyon Merkezi

                                </h5>
                                <p className="contact-item-address">
                                    Güneştepe Mahallesi 1. Fatih Caddesi Asya Apartmanı No:77/D Osmangazi/Bursa
                                </p>
                                       {/* <a className="contact-item-phone mb-2" href="tel:05317637834">
                                    0850 220 70 90
                                </a> */}
                                <a className="contact-item-email mb-3" href="mailto:kuryeik@fiyuu.com.tr">
                                    kuryeik@fiyuu.com.tr
                                </a>

                            </div>
                            <div className="iframe-area">


                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1367.298138888947!2d28.994810119836902!3d40.25891371585232!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14ca14e10f9ec4e3%3A0x491e911914747ba6!2zR8O8bmXFn3RlcGUsIDEuIEZhdGloIENkIE5vOjc3LCAxNjAwMCBPc21hbmdhemnMhy9CdXJzYQ!5e0!3m2!1str!2str!4v1724236914281!5m2!1str!2str"
                                    style={{ border: "0" }} allowFullScreen loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                        <div data-animation="zoomIn" data-animation-delay="50ms"
                            className="contact-cards__item d-flex flex-column justify-content-between align-items-start">
                            <div className="d-flex flex-column justify-content-start align-items-start">
                                <h5 className="contact-item-title">
                                    Adana Operasyon Merkezi
                                </h5>
                                <p className="contact-item-address">
                                    Gürselpaşa Mahallesi 75320 Sokak Nirvana Plus Apartmanı Zemin Kat No:7/A Seyhan/Adana
                                </p>
                                       {/* <a className="contact-item-phone mb-2" href="tel:05317637834">
                                    0850 220 70 90
                                </a> */}
                                <a className="contact-item-email mb-3" href="mailto:kuryeik@fiyuu.com.tr">
                                    kuryeik@fiyuu.com.tr
                                </a>

                            </div>
                            <div className="iframe-area">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3185.4242594451994!2d35.25709167731558!3d37.02352937218456!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x152888f52e2c0749%3A0x64b099e4577783b9!2zTmlydmFuYSBQbHVzIEhhbGxhw6cgxLBuxZ9hYXQ!5e0!3m2!1str!2str!4v1722409964926!5m2!1str!2str"
                                    style={{ border: "0" }} allowFullScreen loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"></iframe>

                            </div>
                        </div>

                        <div data-animation="zoomIn" data-animation-delay="50ms"
                            className="contact-cards__item d-flex flex-column justify-content-between align-items-start">
                            <div className="d-flex flex-column justify-content-start align-items-start">
                                <h5 className="contact-item-title">
                                    Antalya Operasyon Merkezi
                                </h5>
                                <p className="contact-item-address">
                                    Yüzüncüyıl Bulvarı, Altındağ Mahallesi 12/A City Plaza Muratpaşa/Antalya
                                </p>
                                       {/* <a className="contact-item-phone mb-2" href="tel:05317637834">
                                    0850 220 70 90
                                </a> */}
                                <a className="contact-item-email mb-3" href="mailto:kuryeik@fiyuu.com.tr">
                                    kuryeik@fiyuu.com.tr
                                </a>
                            </div>
                            <div className="iframe-area">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3190.9848508307396!2d30.689348012881137!3d36.89071067210555!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14c3917d945afce3%3A0xc8213cc95e29e3e8!2sCity%20Plaza!5e0!3m2!1str!2str!4v1724236054048!5m2!1str!2str"
                                    style={{ border: "0" }} allowFullScreen loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>

                        <div data-animation="zoomIn" data-animation-delay="50ms"
                            className="contact-cards__item d-flex flex-column justify-content-between align-items-start">
                            <div className="d-flex flex-column justify-content-start align-items-start">
                                <h5 className="contact-item-title">
                                    Konya Operasyon Merkezi
                                </h5>
                                <p className="contact-item-address">
                                Turgut Özal Caddesi no 7F Binsa İş Merkezi Selçuklu/Konya
                                </p>
                                       {/* <a className="contact-item-phone mb-2" href="tel:05317637834">
                                    0850 220 70 90
                                </a> */}
                                <a className="contact-item-email mb-3" href="mailto:kuryeik@fiyuu.com.tr">
                                    kuryeik@fiyuu.com.tr
                                </a>

                            </div>
                            <div className="iframe-area">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3144.471439858422!2d32.489646125987534!3d37.989462749763995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d08c32dafcc4e7%3A0x4ede8345f397c404!2sBinsa%20Ticaret%20Merkezi!5e0!3m2!1str!2str!4v1724237134793!5m2!1str!2str"
                                    style={{ border: "0" }} allowFullScreen loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                        <div data-animation="zoomIn" data-animation-delay="50ms"
                            className="contact-cards__item d-flex flex-column justify-content-between align-items-start">
                            <div className="d-flex flex-column justify-content-start align-items-start">
                                <h5 className="contact-item-title">
                                    Diyarbakır Operasyon Merkezi
                                </h5>
                                <p className="contact-item-address">
                                    Mezopotamya Mah. 329. Sok. A Blok No:21 Kayapınar / Diyarbakır
                                </p>
                                       {/* <a className="contact-item-phone mb-2" href="tel:05317637834">
                                    0850 220 70 90
                                </a> */}
                                <a className="contact-item-email mb-3" href="mailto:kuryeik@fiyuu.com.tr">
                                    kuryeik@fiyuu.com.tr
                                </a>

                            </div>
                            <div className="iframe-area">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3146.7997616762236!2d40.15327979485314!3d37.935108099609174!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40751e564a054af9%3A0xfb361ccb06054985!2sMezopotamya%2C%20329.%20Sk.%2C%2021070%20Kayap%C4%B1nar%2FDiyarbak%C4%B1r!5e0!3m2!1str!2str!4v1712146610195!5m2!1str!2str"
                                    style={{ border: "0" }} allowFullScreen loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                        <div data-animation="zoomIn" data-animation-delay="50ms"
                            className="contact-cards__item d-flex flex-column justify-content-between align-items-start">
                            <div className="d-flex flex-column justify-content-start align-items-start">
                                <h5 className="contact-item-title">
                                Samsun Operasyon Merkezi 
                                </h5>
                                <p className="contact-item-address">
                                Yenimahalle İzmir Cad. No.22/A-19 <br /> Atakum / Samsun
                                </p>
                                       {/* <a className="contact-item-phone mb-2" href="tel:05317637834">
                                    0850 220 70 90
                                </a> */}
                                <a className="contact-item-email mb-3" href="mailto:kuryeik@fiyuu.com.tr">
                                    kuryeik@fiyuu.com.tr
                                </a>

                            </div>

                            <div className="iframe-area">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2995.202640773908!2d36.232351576129375!3d41.347948998440515!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4088792b984868f9%3A0xfec77a650b483293!2sYenimahalle%2C%20%C4%B0zmir%20Cd.%20No%3A22%2C%2055200%20Atakum%2FSamsun!5e0!3m2!1str!2str!4v1724237235849!5m2!1str!2str"
                                    style={{ border: "0" }} allowFullScreen loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact;