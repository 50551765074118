import { useEffect } from "react";
import { useAnimation } from "../hooks/useAnimation";

const KVKBasvuru = () => {
    const animate = useAnimation()

    useEffect(() => {
        animate()
        window.scrollTo(0, 0);
    }, [])


    return (
        <div className="d-flex justify-content-center page-ctr flex-column align-items-center">
            <div className="w-100 page-container flex-column justify-content-center align-items-center">
                <h1 className="page-title mb-5">
                    ATA	EXPRESS	ELEKTRONİK	İLETİŞİM	TANITIM	PAZARLAMA	DAĞITIM	SANAYİ	VE	TİCARET	A.Ş.
                    VERİ	SAHİBİ	BAŞVURU	FORMU
                </h1>
                <div className="page-content-card mt-5">
                    <p className="mb-0">
                        <strong>
                            1. Başvuru	Yöntemi
                        </strong>
                    </p>
                    <p className="mb-4">
                        6698	 sayılı	 Kişisel	 Verilerin	 Korunması	 Kanunu’nun	 (“Kanun”) 11.	 Maddesinde	 sayılan	 haklarınız
                        kapsamındaki	 taleplerinizi, Kanun’un	 13.	 maddesi	 ile	 Veri	 Sorumlusuna	 Başvuru	 Usul	 ve	 Esasları
                        Hakkında	 Tebliğ’in	 5.	 maddesi	 gereğince,	 işbu	 form	 ile	 aşağıda	 açıklanan	 yöntemlerden biriyle
                        başvurunuzu	Şirketimize	iletebilirsiniz.
                    </p>
                    <table>
                        <tbody>
                            <tr>
                                <th></th>
                                <th>BAŞVURU YÖNTEMİ</th>
                                <th>BAŞVURU	YAPILACAK ADRES</th>
                                <th>BAŞVURUDA	GÖSTERİLECEK	BİLGİ</th>
                            </tr>
                        </tbody>
                        <tbody>
                            <tr>
                                <th>1. Yazılı	Olarak	Başvuru</th>
                                <td>Islak	imzalı	şahsen
                                    başvuru	veya
                                    Noter	vasıtasıyla</td>
                                <td>DİKİLİTAŞ	MAH.	EMİRHAN
                                    CAD.	ATAKULE	109/A,
                                    KAT:15 Beşiktaş/İstanbul</td>
                                <td>Zarfın/tebligatın	üzerine	“Kişisel
                                    Verilerin	Korunması	Kanunu
                                    Kapsamında	Bilgi	Talebi”
                                    yazılacaktır.</td>
                            </tr>
                        </tbody>
                    </table>
                    <p className="mb-4">
                        <strong>      2. Kimlik ve	İletişim	Bilgileriniz</strong>

                    </p>
                    <p className="mb-0">Lütfen	 sizinle	 iletişime	 geçebilmemiz	 ve	 kimliğinizi	 doğrulayabilmemiz	 adına	 aşağıdaki	 alanları
                        doldurunuz.</p>
                    <p className="mb-0">
                        <strong>
                            3. Şirketimiz	ile	İlişkiniz
                        </strong>
                    </p>
                    <p className="mb-0">
                        <strong>4. Talep Konusu</strong>
                    </p>

                </div>
            </div>
        </div>
    )
}

export default KVKBasvuru;