export const CITIES = [
  {
    province: "ADANA",
    plaka: 1,
    ilceleri: [
      "ALADAĞ",
      "BEYAZEVLER",
      "CEYHAN",
      "DÖRTYOL",
      "DÖŞEME",
      "FATİH",
      "FEKE",
      "GÜZELYALI",
      "HUZUREVLER",
      "KARAISALI",
      "KARATAŞ",
      "KOZAN",
      "MAHVESIĞMAZ",
      "MERKEZ",
      "PINAR",
      "POZANTI",
      "SAIMBEYLI",
      "SARIÇAM",
      "SEYHAN",
      "SÜMER",
      "TELLİDERE",
      "TOROS",
      "TUFANBEYLI",
      "TÜRKMENBAŞI",
      "YENİBARAJ",
      "YEŞİLYURT",
      "YUMURTALIK",
      "YURT",
      "YÜREĞİR",
      "ÇUKUROVA",
      "İMAMOĞLU",
    ],
  },
  {
    province: "ADIYAMAN",
    plaka: 2,
    ilceleri: [
      "BESNI",
      "GERGER",
      "GÖLBAŞI",
      "KAHTA",
      "MERKEZ",
      "SAMSAT",
      "SINCIK",
      "TUT",
      "ÇELIKHAN",
    ],
  },
  {
    province: "AFYON",
    plaka: 3,
    ilceleri: [
      "BASMAKÇI",
      "BAYAT",
      "BOLVADIN",
      "DAZKIRI",
      "DINAR",
      "EMIRDAG",
      "EVCILER",
      "HOCALAR",
      "IHSANIYE",
      "ISCEHISAR",
      "KIZILÖREN",
      "MERKEZ",
      "SANDIKLI",
      "SINANPASA",
      "SUHUT",
      "SULTANDAGI",
      "ÇAY",
      "ÇOBANLAR",
    ],
  },
  {
    province: "AĞRI",
    plaka: 4,
    ilceleri: [
      "DIYADIN",
      "DOĞUBAYAZIT",
      "ELEŞKIRT",
      "HAMUR",
      "MERKEZ",
      "PATNOS",
      "TAŞLIÇAY",
      "TUTAK",
    ],
  },
  {
    province: "AKSARAY",
    plaka: 68,
    ilceleri: [
      "AĞAÇÖREN",
      "ESKIL",
      "GÜLAĞAÇ",
      "GÜZELYURT",
      "MERKEZ",
      "ORTAKÖY",
      "SARIYAHŞI",
      "SULTANHANI",
    ],
  },
  {
    province: "AMASYA",
    plaka: 5,
    ilceleri: [
      "GÖYNÜCEK",
      "GÜMÜŞHACIKÖY",
      "HAMAMÖZÜ",
      "MERKEZ",
      "MERZİFON",
      "SULUOVA",
      "TAŞOVA",
    ],
  },
  {
    province: "ANKARA",
    plaka: 6,
    ilceleri: [
      "AKYURT",
      "ALTINDAĞ",
      "AYAŞ",
      "BAHÇELİEVLER",
      "BALÂ",
      "BATIKENT",
      "BEYPAZARI",
      "ELMADAĞ",
      "ERYAMAN",
      "ETİMESGUT",
      "EVREN",
      "GÖLBAŞI",
      "GÜDÜL",
      "HAYMANA",
      "IŞINLAR",
      "KAHRAMANKAZAN",
      "KALECIK",
      "KEÇİÖREN",
      "KIZILCAHAMAM",
      "KORU",
      "MAMAK",
      "NALLIHAN",
      "POLATLI",
      "PURSAKLAR",
      "SİNCAN",
      "YENI MAHALLE",
      "ÇAMLIDERE",
      "ÇANKAYA",
      "ÇUBUK",
      "ŞEREFLIKOÇHISAR",
    ],
  },
  {
    province: "ANTALYA",
    plaka: 7,
    ilceleri: [
      "AKSEKI",
      "AKSU",
      "ALANYA",
      "DEMRE",
      "DÖŞEMEALTI",
      "ELMALI",
      "FİNİKE",
      "GAZİPAŞA",
      "GÜNDOĞMUŞ",
      "KAŞ",
      "KEMER",
      "KEPEZ",
      "KONYAALTI",
      "KORKUTELI",
      "KUMLUCA",
      "KUNDU",
      "LARA",
      "MANAVGAT",
      "MERKEZ",
      "MURATPAŞA",
      "SERİK",
      "ÇANKAYA",
      "İBRADI",
    ],
  },
  {
    province: "ARDAHAN",
    plaka: 75,
    ilceleri: ["DAMAL", "GÖLE", "HANAK", "MERKEZ", "POSOF", "ÇILDIR"],
  },
  {
    province: "ARTVİN",
    plaka: 8,
    ilceleri: [
      "ARDANUÇ",
      "ARHAVI",
      "BORÇKA",
      "HOPA",
      "KEMALPASA",
      "MERKEZ",
      "MURGUL",
      "SAVSAT",
      "YUSUFELI",
    ],
  },
  {
    province: "AYDIN",
    plaka: 9,
    ilceleri: [
      "BOZDOĞAN",
      "BUHARKENT",
      "DİDİM",
      "EFELER",
      "GERMENCIK",
      "KARACASU",
      "KARPUZLU",
      "KOÇARLI",
      "KUYUCAK",
      "KUŞADASI",
      "KÖŞK",
      "MERKEZ",
      "NAZİLLİ",
      "SULTANHISAR",
      "SÖKE",
      "YENIPAZAR",
      "ÇINE",
      "İNCIRLIOVA",
    ],
  },
  {
    province: "BALIKESİR",
    plaka: 10,
    ilceleri: [
      "ALTIEYLÜL",
      "AYVALIK",
      "BALYA",
      "BANDIRMA",
      "BIGADIÇ",
      "BURHANIYE",
      "DURSUNBEY",
      "EDREMİT",
      "ERDEK",
      "GÖMEÇ",
      "GÖNEN",
      "HAVRAN",
      "IVRINDI",
      "KARESİ",
      "KEPSUT",
      "MANYAS",
      "MARMARA",
      "MERKEZ",
      "SAVASTEPE",
      "SINDIRGI",
      "SUSURLUK",
    ],
  },
  {
    province: "BARTIN",
    plaka: 74,
    ilceleri: ["AMASRA", "BARTIN", "KURUCAŞİLE", "MERKEZ", "ULUS"],
  },
  {
    province: "BATMAN",
    plaka: 72,
    ilceleri: ["BEŞIRI", "GERCÜŞ", "HASANKEYF", "KOZLUK", "MERKEZ", "SASON"],
  },
  {
    province: "BAYBURT",
    plaka: 69,
    ilceleri: ["AYDINTEPE", "DEMIRÖZÜ", "MERKEZ"],
  },
  {
    province: "BİLECİK",
    plaka: 11,
    ilceleri: [
      "BOZÜYÜK",
      "GÖLPAZARI",
      "INHISAR",
      "MERKEZ",
      "OSMANELİ",
      "PAZARYERI",
      "SÖGÜT",
      "YENIPAZAR",
    ],
  },
  {
    province: "BİNGÖL",
    plaka: 12,
    ilceleri: [
      "ADAKLI",
      "GENÇ",
      "KARLIOVA",
      "KIGI",
      "MERKEZ",
      "SOLHAN",
      "YAYLADERE",
      "YEDISU",
    ],
  },
  {
    province: "BİTLİS",
    plaka: 13,
    ilceleri: [
      "ADILCEVAZ",
      "AHLAT",
      "GÜROYMAK",
      "HİZAN",
      "MERKEZ",
      "MUTKI",
      "SEYHAN",
      "TATVAN",
    ],
  },
  {
    province: "BOLU",
    plaka: 14,
    ilceleri: [
      "DÖRTDIVAN",
      "GEREDE",
      "GÖYNÜK",
      "KIBRISCIK",
      "MENGEN",
      "MERKEZ",
      "MUDURNU",
      "SEBEN",
      "YENIÇAĞA",
    ],
  },
  {
    province: "BURDUR",
    plaka: 15,
    ilceleri: [
      "ALTINYAYLA",
      "AĞLASUN",
      "BUCAK",
      "GÖLHISAR",
      "KARAMANLI",
      "KEMER",
      "MERKEZ",
      "TEFENNI",
      "YEŞILOVA",
      "ÇAVDIR",
      "ÇELTIKÇI",
    ],
  },
  {
    province: "BURSA",
    plaka: 16,
    ilceleri: [
      "ATAŞEHİR",
      "BÜYÜKORHAN",
      "DEMİRKAPI",
      "GEMLİK",
      "GÖRÜKLE",
      "GÜRSU",
      "HARMANCIK",
      "KARACABEY",
      "KELES",
      "KESTEL",
      "MERKEZ",
      "MUDANYA",
      "MUSTAFAKEMALPAŞA",
      "NİLÜFER",
      "ORHANELI",
      "ORHANGAZİ",
      "OSMANGAZİ",
      "YENIŞEHIR",
      "YILDIRIM",
      "İNEGÖL",
      "İNKAYA",
      "İZNIK",
    ],
  },
  {
    province: "ÇANAKKALE",
    plaka: 17,
    ilceleri: [
      "AYVACIK",
      "BAYRAMİÇ",
      "BOZCAADA",
      "BİGA",
      "ECEABAT",
      "EZINE",
      "GELIBOLU",
      "GÖKÇEADA",
      "LAPSEKI",
      "MERKEZ",
      "YENICE",
      "ÇAN",
    ],
  },
  {
    province: "ÇANKIRI",
    plaka: 18,
    ilceleri: [
      "ATKARACALAR",
      "BAYRAMÖREN",
      "ELDIVAN",
      "ILGAZ",
      "KIZILIRMAK",
      "KORGUN",
      "KURŞUNLU",
      "MERKEZ",
      "ORTA",
      "YAPRAKLI",
      "ÇERKEŞ",
      "ŞABANÖZÜ",
    ],
  },
  {
    province: "ÇORUM",
    plaka: 19,
    ilceleri: [
      "ALACA",
      "BAYAT",
      "BOĞAZKALE",
      "DODURGA",
      "KARGI",
      "LAÇIN",
      "MECITÖZÜ",
      "MERKEZ",
      "ORTAKÖY",
      "OSMANCIK",
      "OĞUZLAR",
      "SUNGURLU",
      "UĞURLUDAĞ",
      "İSKILIP",
    ],
  },
  {
    province: "DENİZLİ",
    plaka: 20,
    ilceleri: [
      "ACIPAYAM",
      "BABADAG",
      "BAKLAN",
      "BEKILLI",
      "BEYAGAÇ",
      "BOZKURT",
      "BULDAN",
      "GÜNEY",
      "HONAZ",
      "KALE",
      "MERKEZ",
      "MERKEZEFENDİ",
      "PAMUKKALE",
      "SARAYKÖY",
      "SERINHISAR",
      "TAVAS",
      "ÇAL",
      "ÇAMELI",
      "ÇARDAK",
      "ÇIVRIL",
    ],
  },
  {
    province: "DİYARBAKIR",
    plaka: 21,
    ilceleri: [
      "BAĞLAR",
      "BISMIL",
      "DICLE",
      "EGIL",
      "ERGANİ",
      "HANI",
      "HAZRO",
      "KAYAPINAR",
      "KOCAKÖY",
      "KULP",
      "LICE",
      "MERKEZ",
      "SILVAN",
      "SUR",
      "YENISEHIR",
      "ÇERMIK",
      "ÇINAR",
      "ÇÜNGÜS",
    ],
  },
  {
    province: "DÜZCE",
    plaka: 81,
    ilceleri: [
      "AKÇAKOCA",
      "CUMAYERİ",
      "GÖLYAKA",
      "GÜMÜŞOVA",
      "KAYNAŞLI",
      "MERKEZ",
      "YIĞILCA",
      "ÇILIMLI",
    ],
  },
  {
    province: "EDİRNE",
    plaka: 22,
    ilceleri: [
      "ENEZ",
      "HAVSA",
      "KEŞAN",
      "LALAPASA",
      "MERIÇ",
      "MERKEZ",
      "SÜLOGLU",
      "UZUNKÖPRÜ",
      "İPSALA",
    ],
  },
  {
    province: "ELAZIĞ",
    plaka: 23,
    ilceleri: [
      "AGIN",
      "ALACAKAYA",
      "ARICAK",
      "BASKİL",
      "KARAKOÇAN",
      "KEBAN",
      "KOVANCILAR",
      "MADEN",
      "MERKEZ",
      "PALU",
      "SIVRICE",
    ],
  },
  {
    province: "ERZİNCAN",
    plaka: 24,
    ilceleri: [
      "ILIÇ",
      "KEMAH",
      "KEMALİYE",
      "MERKEZ",
      "OTLUKBELI",
      "REFAHIYE",
      "TERCAN",
      "ÇAYIRLI",
      "ÜZÜMLÜ",
    ],
  },
  {
    province: "ERZURUM",
    plaka: 25,
    ilceleri: [
      "AZİZİYE",
      "AŞKALE",
      "HINIS",
      "HORASAN",
      "KARAYAZI",
      "KARAÇOBAN",
      "KÖPRÜKÖY",
      "MERKEZ",
      "NARMAN",
      "OLTU",
      "OLUR",
      "PALANDÖKEN",
      "PASINLER",
      "PAZARYOLU",
      "TEKMAN",
      "TORTUM",
      "UZUNDERE",
      "YAKUTİYE",
      "YENISEHIR ERZ",
      "ÇAT",
      "İSPİR",
      "ŞENKAYA",
    ],
  },
  {
    province: "ESKİŞEHİR",
    plaka: 26,
    ilceleri: [
      "ALPU",
      "BEYLIKOVA",
      "GÜNYÜZÜ",
      "HAN",
      "MAHMUDIYE",
      "MERKEZ",
      "MIHALGAZI",
      "MIHALIÇÇIK",
      "ODUNPAZARI",
      "SARICAKAYA",
      "SEYITGAZI",
      "SIVRIHISAR",
      "TEPEBAŞI",
      "ÇIFTELER",
      "İNÖNÜ",
    ],
  },
  {
    province: "GAZİANTEP",
    plaka: 27,
    ilceleri: [
      "ARABAN",
      "KARKAMIS",
      "MERKEZ",
      "NURDAGI",
      "NİZİP",
      "OĞUZELİ",
      "YAVUZELI",
      "İSLAHİYE",
      "ŞAHİNBEY",
      "ŞEHİTKAMİL",
    ],
  },
  {
    province: "GİRESUN",
    plaka: 28,
    ilceleri: [
      "ALUCRA",
      "BULANCAK",
      "DERELI",
      "DOGANKENT",
      "ESPİYE",
      "EYNESIL",
      "GÖRELE",
      "GÜCE",
      "KESAP",
      "MERKEZ",
      "PIRAZIZ",
      "SEBINKARAHISAR",
      "TIREBOLU",
      "YAGLIDERE",
      "ÇAMOLUK",
      "ÇANAKÇI",
      "ŞEHİTKAMİL",
    ],
  },
  {
    province: "GÜMÜŞHANE",
    plaka: 29,
    ilceleri: [
      "GÜMÜŞHANE",
      "KELKIT",
      "KÖSE",
      "KÜRTÜN",
      "MERKEZ",
      "TORUL",
      "ŞIRAN",
    ],
  },
  {
    province: "HAKKARİ",
    plaka: 30,
    ilceleri: ["MERKEZ", "SEMDINLI", "YÜKSEKOVA", "ÇUKURCA"],
  },
  {
    province: "HATAY",
    plaka: 31,
    ilceleri: [
      "ALTINÖZÜ",
      "ANTAKYA",
      "ARSUZ",
      "BELEN",
      "DEFNE",
      "DÖRTYOL",
      "ERZIN",
      "HASSA",
      "KIRIKHAN",
      "KUMLU",
      "MERKEZ",
      "PAYAS",
      "REYHANLI",
      "SAMANDAĞ",
      "YAYLADAĞI",
      "İSKENDERUN",
    ],
  },
  {
    province: "IĞDIR",
    plaka: 76,
    ilceleri: ["ARALIK", "KARAKOYUNLU", "MERKEZ", "TUZLUCA"],
  },
  {
    province: "ISPARTA",
    plaka: 32,
    ilceleri: [
      "AKSU",
      "ATABEY",
      "EĞIRDIR",
      "GELENDOST",
      "GÖNEN",
      "KEÇIBORLU",
      "MERKEZ",
      "SENIRKENT",
      "SÜTÇÜLER",
      "ULUBORLU",
      "YALVAÇ",
      "YENIŞARBADEMLI",
      "ŞARKIKARAAĞAÇ",
    ],
  },
  {
    province: "İSTANBUL",
    plaka: 34,
    ilceleri: [
      "ADALAR",
      "ARNAVUTKÖY",
      "ATAŞEHİR",
      "AVCILAR",
      "BAHÇELİEVLER",
      "BAKIRKÖY",
      "BAYRAMPAŞA",
      "BAĞCILAR",
      "BAŞAKŞEHİR",
      "BEYKOZ",
      "BEYLİKDÜZÜ",
      "BEYOĞLU",
      "BEŞİKTAŞ",
      "BÜYÜKÇEKMECE",
      "EMİNÖNÜ",
      "ESENLER",
      "ESENYURT",
      "EYÜPSULTAN",
      "FATİH",
      "GAZİOSMANPAŞA",
      "GÜNGÖREN",
      "KADIKÖY",
      "KARTAL",
      "KAĞITHANE",
      "KEMERBURGAZ",
      "KÜÇÜKÇEKMECE",
      "MALTEPE",
      "PENDİK",
      "SANCAKTEPE",
      "SARIYER",
      "SULTANBEYLİ",
      "SULTANGAZİ",
      "SİLİVRİ",
      "TUZLA",
      "YENIMAHALLLE",
      "ZEYTİNBURNU",
      "ÇATALCA",
      "ÇEKMEKÖY",
      "ÜMRANİYE",
      "ÜSKÜDAR",
      "ŞİLE",
      "ŞİŞLİ",
    ],
  },
  {
    province: "İZMİR",
    plaka: 35,
    ilceleri: [
      "ALİAĞA",
      "BALÇOVA",
      "BAYINDIR",
      "BAYRAKLI",
      "BERGAMA",
      "BEYDAG",
      "BORNOVA",
      "BUCA",
      "DIKILI",
      "FOÇA",
      "GAZİEMİR",
      "GÜZELBAHÇE",
      "KARABAGLAR",
      "KARABURUN",
      "KARŞIYAKA",
      "KEMALPAŞA",
      "KINIK",
      "KIRAZ",
      "KONAK",
      "MENDERES",
      "MENEMEN",
      "MERKEZ",
      "NARLIDERE",
      "SEFERİHİSAR",
      "SELÇUK",
      "TORBALI",
      "TİRE",
      "URLA",
      "YEŞİLYURT",
      "ÇEŞME",
      "ÇİĞLİ",
      "ÖDEMIS",
    ],
  },
  {
    province: "KAHRAMANMARAŞ",
    plaka: 46,
    ilceleri: [
      "AFŞIN",
      "ANDIRIN",
      "DULKADIROĞLU",
      "EKINÖZÜ",
      "ELBISTAN",
      "GÖKSUN",
      "MERKEZ",
      "NURHAK",
      "ONİKİŞUBAT",
      "PAZARCIK",
      "TÜRKOĞLU",
      "ÇAĞLAYANCERIT",
    ],
  },
  {
    province: "KARABÜK",
    plaka: 78,
    ilceleri: [
      "EFLANI",
      "ESKIPAZAR",
      "MERKEZ",
      "OVACIK",
      "SAFRANBOLU",
      "YENICE",
    ],
  },
  {
    province: "KARAMAN",
    plaka: 70,
    ilceleri: [
      "AYRANCI",
      "BAŞYAYLA",
      "ERMENEK",
      "KAZIMKARABEKIR",
      "MERKEZ",
      "SARIVELILER",
    ],
  },
  {
    province: "KARS",
    plaka: 36,
    ilceleri: [
      "AKYAKA",
      "ARPAÇAY",
      "DIGOR",
      "KAĞIZMAN",
      "MERKEZ",
      "SARIKAMIŞ",
      "SELIM",
      "SUSUZ",
    ],
  },
  {
    province: "KASTAMONU",
    plaka: 37,
    ilceleri: [
      "ABANA",
      "ARAÇ",
      "AZDAVAY",
      "AĞLI",
      "BOZKURT",
      "CIDE",
      "DADAY",
      "DEVREKANI",
      "DOĞANYURT",
      "HANÖNÜ",
      "KÜRE",
      "MERKEZ",
      "PINARBAŞI",
      "SEYDILER",
      "TAŞKÖPRÜ",
      "TOSYA",
      "ÇATALZEYTIN",
      "İHSANGAZI",
      "İNEBOLU",
      "ŞENPAZAR",
    ],
  },
  {
    province: "KAYSERİ",
    plaka: 38,
    ilceleri: [
      "AKKISLA",
      "BÜNYAN",
      "DEVELİ",
      "FELAHIYE",
      "HACILAR",
      "INCESU",
      "KOCASİNAN",
      "MELİKGAZİ",
      "PINARBASI",
      "SARIOGLAN",
      "SARIZ",
      "SELÇUKLU",
      "TALAS",
      "TOMARZA",
      "YAHYALI",
      "YESILHISAR",
      "ÖZVATAN",
    ],
  },
  {
    province: "KİLİS",
    plaka: 79,
    ilceleri: ["ELBEYLI", "MERKEZ", "MUSABEYLI", "POLATELI"],
  },
  {
    province: "KIRIKKALE",
    plaka: 71,
    ilceleri: [
      "BAHŞILI",
      "BALIŞEYH",
      "DELICE",
      "KARAKEÇİLİ",
      "KESKİN",
      "MERKEZ",
      "SULAKYURT",
      "YAHŞİHAN",
      "ÇELEBI",
    ],
  },
  {
    province: "KIRKLARELİ",
    plaka: 39,
    ilceleri: [
      "BABAESKİ",
      "DEMIRKÖY",
      "KOFÇAZ",
      "LÜLEBURGAZ",
      "MERKEZ",
      "PEHLIVANKÖY",
      "PINARHISAR",
      "VIZE",
    ],
  },
  {
    province: "KIRŞEHİR",
    plaka: 40,
    ilceleri: [
      "AKPINAR",
      "AKÇAKENT",
      "BOZTEPE",
      "KAMAN",
      "MERKEZ",
      "MUCUR",
      "ÇIÇEKDAGI",
    ],
  },
  {
    province: "KOCAELİ",
    plaka: 41,
    ilceleri: [
      "BAŞİSKELE",
      "BEKİRPAŞA",
      "DARICA",
      "DERİNCE",
      "DİLOVASI",
      "GEBZE",
      "GÖLCÜK",
      "KANDIRA",
      "KARAMÜRSEL",
      "KARTEPE",
      "KULLAR",
      "KÖRFEZ",
      "MERKEZ",
      "SARAYBAHÇE",
      "YAHYA KAPTAN",
      "ÇAYIROVA",
      "İZMİT",
    ],
  },
  {
    province: "KONYA",
    plaka: 42,
    ilceleri: [
      "AHIRLI",
      "AKÖREN",
      "AKŞEHIR",
      "ALTINEKIN",
      "BEYŞEHIR",
      "BOZKIR",
      "CIHANBEYLI",
      "DERBENT",
      "DEREBUCAK",
      "DOĞANHISAR",
      "EMIRGAZI",
      "EREĞLİ",
      "GÜNEYSINIR",
      "HADIM",
      "HALKAPINAR",
      "HÜYÜK",
      "ILGIN",
      "KADINHANI",
      "KARAPINAR",
      "KARATAY",
      "KULU",
      "MERAM",
      "SARAYÖNÜ",
      "SELÇUKLU",
      "SEYDIŞEHIR",
      "TAŞKENT",
      "TUZLUKÇU",
      "YALIHÜYÜK",
      "YUNAK",
      "ÇELTIK",
      "ÇUMRA",
    ],
  },
  {
    province: "KÜTAHYA",
    plaka: 43,
    ilceleri: [
      "ALTINTAŞ",
      "ASLANAPA",
      "DOMANIÇ",
      "DUMLUPINAR",
      "EMET",
      "GEDIZ",
      "HISARCIK",
      "MERKEZ",
      "PAZARLAR",
      "SIMAV",
      "TAVŞANLI",
      "ÇAVDARHISAR",
      "ŞAPHANE",
    ],
  },
  {
    province: "MALATYA",
    plaka: 44,
    ilceleri: [
      "AKÇADAĞ",
      "ARAPGIR",
      "ARGUVAN",
      "BATTALGAZİ",
      "DARENDE",
      "DOĞANYOL",
      "DOĞANŞEHİR",
      "HEKIMHAN",
      "KALE",
      "KULUNCAK",
      "MERKEZ",
      "PÜTÜRGE",
      "YAZIHAN",
      "YEŞİLYURT",
    ],
  },
  {
    province: "MANİSA",
    plaka: 45,
    ilceleri: [
      "AHMETLI",
      "AKHİSAR",
      "ALASEHIR",
      "DEMIRCI",
      "GÖLMARMARA",
      "GÖRDES",
      "KIRKAGAÇ",
      "KULA",
      "KÖPRÜBASI",
      "MERKEZ",
      "SALİHLİ",
      "SARIGÖL",
      "SARUHANLI",
      "SELENDI",
      "SOMA",
      "TURGUTLU",
      "YUNUSEMRE",
      "ŞEHZADELER",
    ],
  },
  {
    province: "MARDİN",
    plaka: 57,
    ilceleri: [
      "ARTUKLU",
      "DARGEÇİT",
      "DERIK",
      "KIZILTEPE",
      "MAZIDAGI",
      "MERKEZ",
      "MİDYAT",
      "NUSAYBIN",
      "SAVUR",
      "YESILLI",
      "ÖMERLI",
    ],
  },
  {
    province: "MERSİN",
    plaka: 33,
    ilceleri: [
      "AKDENİZ",
      "ANAMUR",
      "AYDINCIK",
      "BOZYAZI",
      "ERDEMLİ",
      "GÜLNAR",
      "MERKEZ",
      "MEZİTLİ",
      "MUT",
      "SİLİFKE",
      "TARSUS",
      "TOROSLAR",
      "YENİŞEHİR",
      "ÇAMLIYAYLA",
    ],
  },
  {
    province: "MUĞLA",
    plaka: 48,
    ilceleri: [
      "BODRUM",
      "DALAMAN",
      "DATÇA",
      "FETHİYE",
      "KAVAKLIDERE",
      "KÖYCEĞİZ",
      "MARMARİS",
      "MENTEŞE",
      "MERKEZ",
      "MİLAS",
      "ORTACA",
      "SEYDIKEMER",
      "ULA",
      "YATAĞAN",
    ],
  },
  {
    province: "MUŞ",
    plaka: 49,
    ilceleri: ["BULANIK", "HASKÖY", "KORKUT", "MALAZGIRT", "MERKEZ", "VARTO"],
  },
  {
    province: "NEVŞEHİR",
    plaka: 50,
    ilceleri: [
      "ACIGÖL",
      "AVANOS",
      "DERINKUYU",
      "GÜLSEHIR",
      "HACIBEKTAS",
      "KOZAKLI",
      "MERKEZ",
      "NAR",
      "ÜRGÜP",
    ],
  },
  {
    province: "NİĞDE",
    plaka: 51,
    ilceleri: ["ALTUNHİSAR", "BOR", "MERKEZ", "ULUKISLA", "ÇAMARDI", "ÇIFTLIK"],
  },
  {
    province: "ORDU",
    plaka: 52,
    ilceleri: [
      "AKKUŞ",
      "ALTINORDU",
      "AYBASTI",
      "BOZTEPE",
      "FATSA",
      "GÖLKÖY",
      "GÜLYALI",
      "GÜRGENTEPE",
      "KABADÜZ",
      "KABATAŞ",
      "KORGAN",
      "KUMRU",
      "MERKEZ",
      "MESUDIYE",
      "PERŞEMBE",
      "ULUBEY",
      "ÇAMAŞ",
      "ÇATALPINAR",
      "ÇAYBAŞI",
      "ÜNYE",
      "İKIZCE",
    ],
  },
  {
    province: "OSMANİYE",
    plaka: 80,
    ilceleri: [
      "BAHÇE",
      "DÜZIÇI",
      "HASANBEYLI",
      "KADIRLI",
      "MERKEZ",
      "OSMANİYE",
      "SUMBAS",
      "TOPRAKKALE",
    ],
  },
  {
    province: "RİZE",
    plaka: 53,
    ilceleri: [
      "ARDESEN",
      "DEREPAZARI",
      "FINDIKLI",
      "GÜLBAHAR",
      "GÜNEYSU",
      "HEMSIN",
      "IKIZDERE",
      "IYIDERE",
      "KALKANDERE",
      "MERKEZ",
      "PAZAR",
      "ÇAMLIHEMSIN",
      "ÇAYELİ",
    ],
  },
  {
    province: "SAKARYA",
    plaka: 54,
    ilceleri: [
      "ADAPAZARI",
      "AKYAZI",
      "ARİFİYE",
      "ERENLER",
      "FERIZLI",
      "GEYVE",
      "HENDEK",
      "KARAPÜRÇEK",
      "KARASU",
      "KAYNARCA",
      "KOCAALI",
      "MERKEZ",
      "PAMUKOVA",
      "SAPANCA",
      "SELAHİYE",
      "SERDİVAN",
      "SÖĞÜTLÜ",
      "TARAKLI",
    ],
  },
  {
    province: "SAMSUN",
    plaka: 55,
    ilceleri: [
      "19 MAYIS",
      "ALAÇAM",
      "ASARCIK",
      "ATAKUM",
      "AYVACIK",
      "BAFRA",
      "CANİK",
      "HAVZA",
      "KAVAK",
      "LADIK",
      "MERKEZ",
      "ONDOKUZ MAYIS",
      "SALIPAZARI",
      "TEKKEKÖY",
      "TERME",
      "VEZİRKÖPRÜ",
      "YAKAKENT",
      "ÇARŞAMBA",
      "İLKADIM",
    ],
  },
  {
    province: "ŞANLIURFA",
    plaka: 63,
    ilceleri: [
      "AKÇAKALE",
      "BIRECIK",
      "BOZOVA",
      "CEYLANPINAR",
      "EYYÜBİYE",
      "HALFETI",
      "HALİLİYE",
      "HARRAN",
      "HILVAN",
      "KARAKÖPRÜ",
      "MERKEZ",
      "SURUÇ",
      "SİVEREK",
      "VİRANŞEHİR",
    ],
  },
  {
    province: "SİİRT",
    plaka: 56,
    ilceleri: [
      "BAYKAN",
      "ERUH",
      "KURTALAN",
      "MERKEZ",
      "PERVARİ",
      "SIRVAN",
      "TILLO",
    ],
  },
  {
    province: "SİNOP",
    plaka: 57,
    ilceleri: [
      "AYANCIK",
      "BOYABAT",
      "DIKMEN",
      "DURAGAN",
      "ERFELEK",
      "GERZE",
      "MERKEZ",
      "SARAYDÜZÜ",
      "TÜRKELI",
    ],
  },
  {
    province: "ŞIRNAK",
    plaka: 73,
    ilceleri: [
      "BEYTÜŞŞEBAP",
      "CİZRE",
      "GÜÇLÜKONAK",
      "MERKEZ",
      "SİLOPİ",
      "ULUDERE",
      "İDIL",
    ],
  },
  {
    province: "SİVAS",
    plaka: 58,
    ilceleri: [
      "AKINCILAR",
      "ALTINYAYLA",
      "DOGANSAR",
      "DİVRİĞİ",
      "GEMEREK",
      "GÖLOVA",
      "GÜRÜN",
      "HAFIK",
      "IMRANLI",
      "KANGAL",
      "KOYULHİSAR",
      "MERKEZ",
      "SARKISLA",
      "SUSEHRI",
      "ULAS",
      "YILDIZELI",
      "ZARA",
    ],
  },
  {
    province: "TEKİRDAĞ",
    plaka: 59,
    ilceleri: [
      "ERGENE",
      "HAYRABOLU",
      "KAPAKLI",
      "MALKARA",
      "MARMARAEREGLISI",
      "MERKEZ",
      "MURATLI",
      "SARAY",
      "SARKÖY",
      "SÜLEYMANPAŞA",
      "ÇERKEZKÖY",
      "ÇORLU",
    ],
  },
  {
    province: "TOKAT",
    plaka: 60,
    ilceleri: [
      "ALMUS",
      "ARTOVA",
      "BAŞÇIFTLIK",
      "ERBAA",
      "MERKEZ",
      "NIKSAR",
      "PAZAR",
      "REŞADIYE",
      "SULUSARAY",
      "TURHAL",
      "YEŞILYURT",
      "ZILE",
    ],
  },
  {
    province: "TRABZON",
    plaka: 61,
    ilceleri: [
      "AKÇAABAT",
      "ARAKLI",
      "ARSIN",
      "BEŞIKDÜZÜ",
      "DERNEKPAZARI",
      "DÜZKÖY",
      "HAYRAT",
      "KÖPRÜBAŞI",
      "MAÇKA",
      "MERKEZ",
      "OF",
      "ORTAHİSAR",
      "SÜRMENE",
      "TONYA",
      "VAKFIKEBIR",
      "YOMRA",
      "ÇARŞIBAŞI",
      "ÇAYKARA",
      "ŞALPAZARI",
    ],
  },
  {
    province: "TUNCELİ",
    plaka: 62,
    ilceleri: [
      "HOZAT",
      "MAZGIRT",
      "MERKEZ",
      "NAZIMIYE",
      "OVACIK",
      "PERTEK",
      "PÜLÜMÜR",
      "ÇEMISGEZEK",
    ],
  },
  {
    province: "UŞAK",
    plaka: 64,
    ilceleri: ["BANAZ", "EŞME", "KARAHALLI", "MERKEZ", "SIVASLI", "ULUBEY"],
  },
  {
    province: "VAN",
    plaka: 65,
    ilceleri: [
      "BAHÇESARAY",
      "BAŞKALE",
      "EDREMIT",
      "ERCİŞ",
      "GEVAŞ",
      "GÜRPINAR",
      "MERKEZ",
      "MURADIYE",
      "SARAY",
      "TUŞBA",
      "ÇALDIRAN",
      "ÇATAK",
      "ÖZALP",
      "İPEKYOLU",
    ],
  },
  {
    province: "YALOVA",
    plaka: 77,
    ilceleri: [
      "ALTINOVA",
      "ARMUTLU",
      "MERKEZ",
      "TERMAL",
      "ÇINARCIK",
      "ÇİFTLİKKÖY",
    ],
  },
  {
    province: "YOZGAT",
    plaka: 66,
    ilceleri: [
      "AKDAĞMADENI",
      "AYDINCIK",
      "BOĞAZLIYAN",
      "KADIŞEHRI",
      "MERKEZ",
      "SARAYKENT",
      "SARIKAYA",
      "SORGUN",
      "YENIFAKILI",
      "YERKÖY",
      "ÇANDIR",
      "ÇAYIRALAN",
      "ÇEKEREK",
      "ŞEFAATLI",
    ],
  },
  {
    province: "ZONGULDAK",
    plaka: 67,
    ilceleri: [
      "ALAPLI",
      "DEVREK",
      "EREĞLİ",
      "GÖKÇEBEY",
      "KILIMLI",
      "KOZLU",
      "MERKEZ",
      "ÇAYCUMA",
    ],
  },
];

CITIES.sort((a, b) => a.province.localeCompare(b.province));
