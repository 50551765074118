import { useEffect } from "react";
import { useAnimation } from "../hooks/useAnimation";

const KvkIsleme = () => {
    const animate = useAnimation()

    useEffect(() => {
        animate()
        window.scrollTo(0, 0);
    }, [])

    return (
        <div className="about-us-ctr d-flex justify-content-center flex-column align-items-center">
        <div className="w-100 d-flex flex-column justify-content-center align-items-center">
            <div className="why-us page-container w-100 d-flex flex-column justify-content-start align-items-start">
                <h3 className="title" data-animation="slideInRight" data-animation-delay="50ms">
                Kişisel Verilerin Korunması ve İşlenmesi Politikası
                </h3>
                <div className="container-iframe">
                <iframe className="responsive-iframe" src="/files/Kisisel-Verilerin-Korunmasi-ve-Islenmesi-Politikasi.pdf" style={{ width: "100%" }} width={100} frameBorder="0"></iframe>
                </div>
            </div>
        </div>
    </div>
    )
}

export default KvkIsleme;